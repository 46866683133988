import axios from 'axios'
import { load } from 'recaptcha-v3'

(async function handleSubmit() {
    const recaptcha = await startRecaptcha()

    const FORM_ERROR_MESSAGE = 'Houve um erro ao tentar enviar os dados.'

    const contactForm = document.querySelector('.contato-dimicron__form')
    contactForm.addEventListener('submit', handleFormSubmit)

    async function startRecaptcha() {
        const recaptcha = await load('6LdqYLEjAAAAAKh-rz7DelD27g1ePlfnGuo8lcgA')

        return recaptcha
    }

    async function handleFormSubmit(event) {
        event.preventDefault()

        const data = getCurrentFormInformation()
        const token = await recaptcha.execute('submit')

        try {
            const response = await axios.post('./submit.php', { data, token })
            console.log(response.data)
            if(response.data.status == 'error') {
                handleError(response.data.message)
                return
            } 
            
            cleanForm(response.data.message)
        } catch (error) {
            console.log(error)
            handleError(FORM_ERROR_MESSAGE)
        }
    }

    function getCurrentFormInformation() {
        const payload = {
            name: document.getElementById('nome').value,
            email: document.getElementById('email').value,
            phone: document.getElementById('telefone').value,
            state: document.getElementById('estado').value,
            city: document.getElementById('cidade').value,
            subject: document.getElementById('assunto').value,
            message: document.getElementById('mensagem').value,
        }

        return payload
    }

    function handleError(error) {
        const errorBox = document.querySelector('.contato-dimicron__erro p')
        errorBox.innerHTML = error
    }

    function cleanForm(message) {
        document.getElementById('nome').value = ""
        document.getElementById('email').value = ""
        document.getElementById('telefone').value = ""
        document.getElementById('assunto').value = ""
        document.getElementById('mensagem').value = ""

        const errorBox = document.querySelector('.contato-dimicron__erro p')
        errorBox.innerHTML = message

        grecaptcha.reset()
    }
})()